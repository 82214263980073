import intervalTreeDefaultExport, {
  Interval,
  IntervalTree,
} from 'interval-tree-1d';
import flatten from 'lodash-es/flatten';
import {
  convertToStd140Matrix,
  numElementsStd140TransformMatrix,
} from '../../network/panZoom';
import {
  interpolateQuadColor,
  interpolateScalar,
} from '../../NonConstantUtils';
import {
  ColorQuadruplet,
  ColorTriplet,
  GL_NUM_BYTES_PER_FLOAT,
  IUpdatePatternItem,
  normalizeColorQuadruplet,
  normalizeColorTriplet,
  PersistentFloat32Array,
  UpdateType,
} from '../../sharedComponents/webglUtils';
import {
  failIfValidOrNonExhaustive,
} from '../../Utils';
import {
  defaultMaxNodeRadius,
} from '../../workerStore/network/Utils';
import {
  ActiveStatus,
  IProcessedEdge,
  IProcessedNode,
  RelatedNodesMap,
} from '../../workerStore/network/Utils';
import {
  getSVGPoint,
  ITransformationMatrix,
} from '../panZoom';
import {
  deselectedNodeBackgroundColor,
  hoveredNodeStrokeColor,
  hoveredStrokeWidth,
  regularEdgeColor as importedRegularEdgeColor,
  regularStrokeColor,
  regularStrokeWidth,
  relatedToHoveredEdgeColor as importedRelatedToHoveredEdgeColor,
  relatedToHoveredNodeStrokeColor,
  relatedToHoveredOrSelectedStrokeWidth,
  relatedToHoveredSolidSegmentLength,
  relatedToSelectdOrHighlightedEdgeColor as importedRelatedToSelectedOrHighlightedEdgeColor,
  selectedOrHighlightedStrokeWidth,
  unexportedNodeBackgroundColor,
} from '../Utils';

export interface INodeInternal {
  id: number;
  uniqueKey: string;
  centerX: number;
  centerY: number;
  radius: number;
  fillColor: ColorQuadruplet;
  strokeColor: ColorQuadruplet;
  strokeWidth: number;
}

export interface IEdgeInternal {
  uniqueKey: string;
  startX: number;
  startY: number;
  endX: number;
  endY: number;
  color: ColorQuadruplet;
  width: number;
  lengthOfSolidSegment: number;
}

// Return the number of segments needed to draw a smooth circle.
// Taken from http://slabode.exofire.net/circle_draw.shtml
export const getNumSegmentsForRadius = (radius: number, segmentLength: number) => {
  // formula: (1 - cos(theta)) * r = segmentLength and solve for theta
  const theta = Math.acos(1 - segmentLength / radius);

  return Math.ceil(2 * Math.PI / theta);
};

// Returns all multiples of (2 PI / numSegment)
export const getThetaValues = (numSegment: number) => {
  const result: number[] = [0];
  const base = 2 * Math.PI / numSegment;
  for (let i = 0; i < numSegment; i += 1) {
    const theta = base * i;
    result.push(theta);
  }
  return result;
};

export const getIsPointOnCircumferenceValues = (numSegments: number) => {
  // The zero-th point is the center. The rest are on the circumference:
  const result: number[] = [0];
  for (let i = 0; i < numSegments; i += 1) {
    result.push(1);
  }
  return result;
};

// Returns the indices for the `numSegments` triangles that make up a circle:
// e.g. 0, 1, 2 (first triangle), 0, 2, 3, (second triangle) 0, 3, 4 (third triangle) etc
export const getCircleIndices = (numSegments: number) => {
  const result: number[] = [];
  for (let j = 0; j < numSegments; j += 1) {
    result.push(0);
    result.push(j % numSegments + 1);
    result.push((j + 1) % numSegments + 1);
  }
  return result;
};

const visibleCircleNumSegments = getNumSegmentsForRadius(defaultMaxNodeRadius, 0.2);

export const visibleCircleThetaValues = getThetaValues(visibleCircleNumSegments);
export const visibleIsPointOnCircumferenceValues = getIsPointOnCircumferenceValues(visibleCircleNumSegments);
export const visibleCircleIndices = getCircleIndices(visibleCircleNumSegments);

// We draw 2 instances per node: one for stroke and one for fill:
export const numInstancesPerNode = 2;
// The number of floats belonging to instanced attributes in the vertex shader for nodes:
export const numFloatsPerNodeInstance = 17;

export const writeToNodeBuffers = (
    prevNodes: Map<string, INodeInternal>,
    nextNodes: Map<string, INodeInternal>,
    updatePattern: IUpdatePatternItem[],
    nodesData: PersistentFloat32Array,
  ) => {

  const updatePatternLength = updatePattern.length;
  const buffer = nodesData.buffer;
  nodesData.length = updatePatternLength * numInstancesPerNode * numFloatsPerNodeInstance;

  for (let i = 0; i < updatePatternLength; i += 1) {
    const {key, type} = updatePattern[i];
    let initialCenterX: number, finalCenterX: number;
    let initialCenterY: number, finalCenterY: number;
    let initialRadius: number, finalRadius: number;
    let initialHalfStrokeWidth: number, finalHalfStrokeWidth: number;
    let initialFillColor: ColorQuadruplet, finalFillColor: ColorQuadruplet;
    let initialStrokeColor: ColorQuadruplet, finalStrokeColor: ColorQuadruplet;

    if (type === UpdateType.Enter) {
      const node = nextNodes.get(key)!;
      initialCenterX = finalCenterX = node.centerX;
      initialCenterY = finalCenterY = node.centerY;
      initialRadius = finalRadius = node.radius;
      initialHalfStrokeWidth = finalHalfStrokeWidth = node.strokeWidth / 2;

      initialFillColor = node.fillColor.slice(0) as ColorQuadruplet;
      initialFillColor[3] = 0;
      finalFillColor = node.fillColor.slice(0) as ColorQuadruplet;

      initialStrokeColor = node.strokeColor.slice(0) as ColorQuadruplet;
      initialStrokeColor[3] = 0;
      finalStrokeColor = node.strokeColor.slice(0) as ColorQuadruplet;
    } else if (type === UpdateType.Exit) {
      const node = prevNodes.get(key)!;
      initialCenterX = finalCenterX = node.centerX;
      initialCenterY = finalCenterY = node.centerY;
      initialRadius = finalRadius = node.radius;
      initialHalfStrokeWidth = finalHalfStrokeWidth = node.strokeWidth / 2;

      initialFillColor = node.fillColor.slice(0) as ColorQuadruplet;
      finalFillColor = node.fillColor.slice(0) as ColorQuadruplet;
      finalFillColor[3] = 0;

      initialStrokeColor = node.strokeColor.slice(0) as ColorQuadruplet;
      finalStrokeColor = node.strokeColor.slice(0) as ColorQuadruplet;
      finalStrokeColor[3] = 0;
    } else if (type === UpdateType.Update) {
      const prevNode = prevNodes.get(key)!;
      const nextNode = nextNodes.get(key)!;
      ({
        centerX: initialCenterX, centerY: initialCenterY, radius: initialRadius,
        /* tslint:disable-next-line:trailing-comma */
        fillColor: initialFillColor, strokeColor: initialStrokeColor
      } = prevNode);
      ({
        centerX: finalCenterX, centerY: finalCenterY, radius: finalRadius,
        /* tslint:disable-next-line:trailing-comma */
        fillColor: finalFillColor, strokeColor: finalStrokeColor
      } = nextNode);

      initialHalfStrokeWidth = prevNode.strokeWidth / 2;
      finalHalfStrokeWidth = nextNode.strokeWidth / 2;
    } else {
      failIfValidOrNonExhaustive(type, 'Invalid update type');
      initialCenterX = finalCenterX = 0;
      initialCenterY = finalCenterY = 0;
      initialRadius = finalRadius = 0;
      initialHalfStrokeWidth = finalHalfStrokeWidth = 0;

      initialFillColor = [0, 0, 0, 0];
      finalFillColor = [0, 0, 0, 0];
      initialStrokeColor = [0, 0, 0, 0];
      finalStrokeColor = [0, 0, 0, 0];
    }

    const startingIndexForThisNode = i * numInstancesPerNode * numFloatsPerNodeInstance;
    let floatsSetOnThisInstanceSoFar = 0;

    // These attributes are the same for stroke and fill:
    for (let j = 0; j < numInstancesPerNode; j += 1) {
      const start = startingIndexForThisNode + j * numFloatsPerNodeInstance;
      buffer[start + 0] = initialCenterX;
      buffer[start + 1] = initialCenterY;

      buffer[start + 2] = finalCenterX;
      buffer[start + 3] = finalCenterY;

      buffer[start + 4] = initialRadius;
      buffer[start + 5] = finalRadius;

      buffer[start + 6] = initialHalfStrokeWidth;
      buffer[start + 7] = finalHalfStrokeWidth;
    }

    floatsSetOnThisInstanceSoFar = 8;
    const numFloatsPerColorAttr = 4;

    for (let k = 0; k < numFloatsPerColorAttr; k += 1) {
      // Stroke color (isntance 1):
      buffer[
        startingIndexForThisNode + floatsSetOnThisInstanceSoFar + k
      ] = initialStrokeColor[k];
      buffer[
        startingIndexForThisNode + floatsSetOnThisInstanceSoFar + numFloatsPerColorAttr + k
      ] = finalStrokeColor[k];

      // Fill color (instance 2):
      buffer[
        startingIndexForThisNode + numFloatsPerNodeInstance + floatsSetOnThisInstanceSoFar + k
      ] = initialFillColor[k];
      buffer[
        startingIndexForThisNode + numFloatsPerNodeInstance + floatsSetOnThisInstanceSoFar + numFloatsPerColorAttr + k
      ] = finalFillColor[k];
    }

    floatsSetOnThisInstanceSoFar = 16;
    buffer[startingIndexForThisNode + floatsSetOnThisInstanceSoFar] = 1;
    buffer[startingIndexForThisNode + numFloatsPerNodeInstance + floatsSetOnThisInstanceSoFar] = -1;
  }

  return {
    instanceCount: updatePatternLength * 2,
  };
};

const normalizedUnexportedNodeBackgroundColor = normalizeColorTriplet(unexportedNodeBackgroundColor);
const normalizedDeselectedNodeBackgroundColor = normalizeColorTriplet(deselectedNodeBackgroundColor);
const normalizedRegularStrokeColor = normalizeColorTriplet(regularStrokeColor);
const normalizedHoveredNodeStrokeColor = normalizeColorTriplet(hoveredNodeStrokeColor);
const normalizedRelatedToHoveredNodeStrokeColor = normalizeColorTriplet(relatedToHoveredNodeStrokeColor);

export const convertToInternalNodes = (
    nodeList: IProcessedNode[],
    relatedNodesMap: RelatedNodesMap,
    highlightedProduct: number | undefined,
    selectedProducts: number[],
    hoveredProduct: number | undefined,
    stringToColorTriplet: (colorString: string) => ColorTriplet,
  ) => {

  const {
    relatedToHighlightedOrSelected,
    relatedToHovered,
    selectedAndHighlighted,
  } = getHighlightSets(
    relatedNodesMap, highlightedProduct, selectedProducts, hoveredProduct,
  );

  const nextNodes: Map<string, INodeInternal> = new Map();
  const nextKeys: string[] = [];
  const nodesWithHighlightLength = nodeList.length;
  for (let i = 0; i < nodesWithHighlightLength; i += 1) {
    const {
      id, uniqueKey, scaledX, scaledY, radius,
      active, color,
      // assignedFillAsTriplet, assignedStrokeAsTriplet, strokeWidth
    } = nodeList[i];
    const isHighlighted = selectedAndHighlighted.has(id);

    let assignedFillAsTriplet: ColorTriplet;
    if (active === ActiveStatus.Active) {
      assignedFillAsTriplet = stringToColorTriplet(color);
    } else if (active === ActiveStatus.Deselected) {
      assignedFillAsTriplet = normalizedDeselectedNodeBackgroundColor;
    } else if (active === ActiveStatus.NotExported) {
      assignedFillAsTriplet = normalizedUnexportedNodeBackgroundColor;
    } else {
      failIfValidOrNonExhaustive(active, 'Invalid active status');
      assignedFillAsTriplet = [0, 0, 0];
    }

    let strokeWidth: number;
    if (hoveredProduct === id) {
      strokeWidth = hoveredStrokeWidth;
    } else if (isHighlighted === true) {
      strokeWidth = selectedOrHighlightedStrokeWidth;
    } else if (relatedToHighlightedOrSelected.has(id)) {
      strokeWidth = relatedToHoveredOrSelectedStrokeWidth;
    } else {
      strokeWidth = regularStrokeWidth;
    }

    let assignedStrokeAsTriplet: ColorTriplet;
    if (relatedToHovered.has(id)) {
      assignedStrokeAsTriplet = normalizedRelatedToHoveredNodeStrokeColor;
    } else if (hoveredProduct === id) {
      assignedStrokeAsTriplet = normalizedHoveredNodeStrokeColor;
    } else {
      assignedStrokeAsTriplet = normalizedRegularStrokeColor;
    }

    const internalNode: INodeInternal = {
      id, uniqueKey, radius,
      fillColor: [assignedFillAsTriplet[0], assignedFillAsTriplet[1], assignedFillAsTriplet[2], 1],
      strokeColor: [assignedStrokeAsTriplet[0], assignedStrokeAsTriplet[1], assignedStrokeAsTriplet[2], 1],
      strokeWidth,
      centerX: scaledX,
      centerY: scaledY,
    };
    nextNodes.set(uniqueKey, internalNode);
    nextKeys.push(uniqueKey);
  }
  return {nextNodes, nextKeys};
};

export const normalizedRegularEdgeColor = normalizeColorQuadruplet(importedRegularEdgeColor);
export const normalizedRelatedToHoveredEdgeColor = normalizeColorQuadruplet(importedRelatedToHoveredEdgeColor);
export const normalizedRelatedToSelectedOrHighlightedEdgeColor = normalizeColorQuadruplet(
  importedRelatedToSelectedOrHighlightedEdgeColor,
);

export const convertToInternalEdges = (options: {
    edgeList: IProcessedEdge[],
    relatedNodesMap: RelatedNodesMap,
    highlightedProduct: number | undefined,
    selectedProducts: number[],
    hoveredProduct: number | undefined,
    // Either the canvas's CSS width or height, whichever is larger:
    canvasLargerSide: number,
    regularEdgeColor: ColorQuadruplet,
    relatedToHoveredEdgeColor: ColorQuadruplet,
    relatedToSelectedOrHighlightedEdgeColor: ColorQuadruplet,
    relatedToHoveredEdgeWidth: number,
    relatedToSelectedHighlightedEdgeWidth: number,
    regularEdgeWidth: number,
  }) => {

  const edgeList = options.edgeList;
  const relatedNodesMap = options.relatedNodesMap;
  const highlightedProduct = options.highlightedProduct;
  const selectedProducts = options.selectedProducts;
  const hoveredProduct = options.hoveredProduct;
  const canvasLargerSide = options.canvasLargerSide;
  const regularEdgeColor = options.regularEdgeColor;
  const relatedToHoveredEdgeColor = options.relatedToHoveredEdgeColor;
  const relatedToSelectedOrHighlightedEdgeColor = options.relatedToSelectedOrHighlightedEdgeColor;
  const relatedToHoveredEdgeWidth = options.relatedToHoveredEdgeWidth;
  const relatedToSelectedHighlightedEdgeWidth = options.relatedToSelectedHighlightedEdgeWidth;
  const regularEdgeWidth = options.regularEdgeWidth;

  // TODO: share this function call with `convertToInternalNodes`:
  const {
    relatedToHovered, relatedToHighlightedOrSelected,
  } = getHighlightSets(
    relatedNodesMap, highlightedProduct, selectedProducts, hoveredProduct,
  );

  const nextEdges: Map<string, IEdgeInternal> = new Map();
  const nextRelatedToHoveredKeys: string[] = [];
  const nextUnrelatedToHoveredKeys: string[] = [];
  const edgeListLength = edgeList.length;
  for (let i = 0; i < edgeListLength; i += 1) {
    const edge = edgeList[i];
    const source = edge.nodes[0];
    const target = edge.nodes[1];
    const uniqueKey = edge.uniqueKey;
    const startX = source.scaledX; const startY = source.scaledY; const sourceId = source.id;
    const endX = target.scaledX; const endY = target.scaledY; const targetId = target.id;

    const isRelatedToHovered =
      (relatedToHovered.has(sourceId) && targetId === hoveredProduct) ||
      (relatedToHovered.has(targetId) && sourceId === hoveredProduct);

    const isRelatedToSelectedOrHighlighted =
      (relatedToHighlightedOrSelected.has(sourceId) &&
        (selectedProducts.includes(targetId) || highlightedProduct === targetId)) ||
      (relatedToHighlightedOrSelected.has(targetId) &&
        (selectedProducts.includes(sourceId) || highlightedProduct === sourceId));

    // const color = isRelatedToHovered ? inputRelatedToHoveredEdgeColor : inputRegularEdgeColor;
    let color: ColorQuadruplet;
    if (isRelatedToHovered) {
      color = relatedToHoveredEdgeColor;
    } else if (isRelatedToSelectedOrHighlighted) {
      color = relatedToSelectedOrHighlightedEdgeColor;
    } else {
      color = regularEdgeColor;
    }

    let width: number;
    if (isRelatedToHovered) {
      width = relatedToHoveredEdgeWidth;
    } else if (isRelatedToSelectedOrHighlighted) {
      width = relatedToSelectedHighlightedEdgeWidth;
    } else  {
      width = regularEdgeWidth;
    }
    // const width = isRelatedToHovered ? relatedToHoveredEdgeWidth : regularEdgeWidth;

    // Setting `lengthOfSolidSegment` to `canvasLargerSide` will guarantee that
    // the line is wholly solid because the length of any edge cannot exceed `canvasLargerSide`:
    const lengthOfSolidSegment = isRelatedToHovered ? relatedToHoveredSolidSegmentLength : canvasLargerSide;

    const internalEdge: IEdgeInternal = {
      uniqueKey, startX, startY,
      endX, endY, color, width, lengthOfSolidSegment,
    };
    nextEdges.set(uniqueKey, internalEdge);
    if (isRelatedToHovered === true) {
      nextRelatedToHoveredKeys.push(uniqueKey);
    } else {
      nextUnrelatedToHoveredKeys.push(uniqueKey);
    }
  }

  const nextKeys = nextUnrelatedToHoveredKeys.concat(nextRelatedToHoveredKeys);

  return {
    nextEdges, nextKeys,
  };
};

export const lineReferenceCoordValues = [
  // top left corner:
  0, 0.5,
  // bottom left corner:
  0, -0.5,
  // bottom right corner:
  1, -0.5,
  // top right corner:
  1, 0.5,
];

export const lineIndices = [
  0, 1, 3,
  1, 3, 2,
];
export const lineIndicesCount = lineIndices.length;

export const numFloatsPerEdgeInstance = 10;

export const writeToEdgeBuffers = (
    nextKeys: string[],
    nextEdges: Map<string, IEdgeInternal>,
    edgesData: PersistentFloat32Array,
  ) => {

  const updatePatternLength = nextKeys.length;
  edgesData.length = updatePatternLength * numFloatsPerEdgeInstance;
  const buffer = edgesData.buffer;

  for (let i = 0; i < updatePatternLength; i += 1) {
    const key = nextKeys[i];
    const edge = nextEdges.get(key)!;
    const startX = edge.startX;
    const startY = edge.startY;
    const endX = edge.endX;
    const endY = edge.endY;
    const width = edge.width;
    const lengthOfSolidSegment = edge.lengthOfSolidSegment;
    const color = edge.color;

    const startIndexForCurrentEdge = i * numFloatsPerEdgeInstance;

    let numFloatsSetSoFar = 0;

    buffer[startIndexForCurrentEdge + 0] = startX;
    buffer[startIndexForCurrentEdge + 1] = startY;

    buffer[startIndexForCurrentEdge + 2] = endX;
    buffer[startIndexForCurrentEdge + 3] = endY;

    buffer[startIndexForCurrentEdge + 4] = width;

    numFloatsSetSoFar = 5;

    const numFloatsPerColor = 4;
    for (let j = 0; j < numFloatsPerColor; j += 1) {
      buffer[startIndexForCurrentEdge + numFloatsSetSoFar + j] = color[j];
    }

    numFloatsSetSoFar = 9;

    buffer[startIndexForCurrentEdge + numFloatsSetSoFar] = lengthOfSolidSegment;
  }

  return {
    instanceCount: nextKeys.length,
  };
};

export const interpolateAnimation = (
    updatePattern: IUpdatePatternItem[],
    prevNodes: Map<string, INodeInternal>,
    nextNodes: Map<string, INodeInternal>,
    tweenProgress: number,
  ) => {

  const updatePatternLength = updatePattern.length;
  const keysAfterTermination: string[] = [];
  const nodesMapAfterTermination: Map<string, INodeInternal> = new Map();
  for (let i = 0; i < updatePatternLength; i += 1) {
    const {key, type} = updatePattern[i];
    keysAfterTermination.push(key);
    let interpolatedCenterX: number, interpolatedCenterY: number;
    let interpolatedRadius: number, interpolatedStrokeWidth: number;
    let interpolatedFillColor: ColorQuadruplet, interpolatedStrokeColor: ColorQuadruplet;
    let nodeToLookupNonInterpolatedInfo: INodeInternal;
    if (type === UpdateType.Enter) {
      const node = nextNodes.get(key)!;
      nodeToLookupNonInterpolatedInfo = node;
      interpolatedCenterX = node.centerX;
      interpolatedCenterY = node.centerY;
      interpolatedRadius = node.radius;
      interpolatedStrokeWidth = node.strokeWidth;
      // Take into account the opacity:
      interpolatedStrokeColor = node.strokeColor;
      interpolatedStrokeColor[3] = interpolateScalar(0, 1, tweenProgress);

      interpolatedFillColor = node.fillColor;
      interpolatedFillColor[3] = interpolateScalar(0, 1, tweenProgress);
    } else if (type === UpdateType.Exit) {
      const node = prevNodes.get(key)!;
      nodeToLookupNonInterpolatedInfo = node;
      interpolatedCenterX = node.centerX;
      interpolatedCenterY = node.centerY;
      interpolatedRadius = node.radius;
      interpolatedStrokeWidth = node.strokeWidth;
      // Take into account the opacity:
      interpolatedStrokeColor = node.strokeColor;
      interpolatedStrokeColor[3] = interpolateScalar(1, 0, tweenProgress);

      interpolatedFillColor = node.fillColor;
      interpolatedFillColor[3] = interpolateScalar(1, 0, tweenProgress);
    } else if (type === UpdateType.Update) {
      const prevNode = prevNodes.get(key)!;
      const nextNode = nextNodes.get(key)!;
      nodeToLookupNonInterpolatedInfo = nextNode;

      interpolatedCenterX = interpolateScalar(prevNode.centerX, nextNode.centerX, tweenProgress);
      interpolatedCenterY = interpolateScalar(prevNode.centerY, nextNode.centerY, tweenProgress);
      interpolatedRadius = interpolateScalar(prevNode.radius, nextNode.radius, tweenProgress);
      interpolatedStrokeWidth = interpolateScalar(prevNode.strokeWidth, nextNode.strokeWidth, tweenProgress);

      interpolatedFillColor = interpolateQuadColor(prevNode.fillColor, nextNode.fillColor, tweenProgress);
      interpolatedStrokeColor = interpolateQuadColor(prevNode.strokeColor, nextNode.strokeColor, tweenProgress);
    } else {
      failIfValidOrNonExhaustive(type, 'Invalid type');
      // These lines will never be run:
      interpolatedCenterX = 0;
      interpolatedCenterY = 0;
      interpolatedRadius = 0;
      interpolatedStrokeWidth = 0;
      interpolatedStrokeColor = [0, 0, 0, 0];
      interpolatedFillColor = [0, 0, 0, 0];
      nodeToLookupNonInterpolatedInfo = {} as any;
    }

    const interpolatedNode: INodeInternal = {
      id: nodeToLookupNonInterpolatedInfo.id,
      uniqueKey: nodeToLookupNonInterpolatedInfo.uniqueKey,
      centerX: interpolatedCenterX,
      centerY: interpolatedCenterY,
      radius: interpolatedRadius,
      strokeWidth: interpolatedStrokeWidth,
      strokeColor: interpolatedStrokeColor,
      fillColor: interpolatedFillColor,
    };
    nodesMapAfterTermination.set(key, interpolatedNode);
  }
  return {
    keysAfterTermination,
    nodesMapAfterTermination,
  };
};

export const getIntervalTrees = (
    nextKeys: string[],
    nextNodes: Map<string, INodeInternal>,
    // Need to do this because doing this
    // `import createIntervalTree from 'interval-tree-1d`
    // works in TypeScript but not in jest so we need to import the module separately
    // in the typescript and jest worlds.
    // TODO: figure out why there's discrepancy between how jest and typescript produces
    // different import calls
    createIntervalTree: typeof intervalTreeDefaultExport,
  ) => {
  const nextKeysLength = nextKeys.length;
  const xIntervals: Array<Interval<[number, number, number]>> = [];
  const yIntervals: Array<Interval<[number, number, number]>> = [];
  for (let i = 0; i < nextKeysLength; i += 1) {
    const node = nextNodes.get(nextKeys[i])!;
    const id = node.id;
    const centerX = node.centerX;
    const centerY = node.centerY;
    const radius = node.radius;
    const x0 = centerX - radius;
    const x1 = centerX + radius;
    const y0 = centerY - radius;
    const y1 = centerY + radius;
    const xInterval: Interval<[number, number, number]> = [x0, x1, [id, centerX, radius]];
    const yInterval: Interval<[number, number, number]> = [y0, y1, [id, centerY, radius]];
    xIntervals.push(xInterval);
    yIntervals.push(yInterval);
  }

  return {
    xIntervalTree: createIntervalTree(xIntervals),
    yIntervalTree: createIntervalTree(yIntervals),
  };
};

export const searchForHits = (
    xIntervalTree: IntervalTree<[number, number, number]>,
    yIntervalTree: IntervalTree<[number, number, number]>,
    xMax: number,
    yMax: number,
    xTarget: number,
    yTarget: number,
    transformationMatrix: ITransformationMatrix,
  ) => {

  if (xTarget < 0 || xTarget > xMax || yTarget < 0 || yTarget > yMax) {
    return undefined;
  }  else {
    // Undo the transformation to figure out where th clicked point would have been
    // before any pan/zoom action...
    const untransformed = getSVGPoint(transformationMatrix, {x: xTarget, y: yTarget});
    const untransformedX = untransformed.x;
    const untransformedY = untransformed.y;

    // ... then try to find which circles are hit:
    const xMatchIds: number[][] = [];
    xIntervalTree.queryPoint(untransformedX, (interval: Interval<[number, number, number]>) => {
      xMatchIds.push(interval[2]);
      return undefined;
    });

    const yMatchIds: number[][] = [];
    yIntervalTree.queryPoint(untransformedY, (interval: Interval<[number, number, number]>) => {
      yMatchIds.push(interval[2]);
      return undefined;
    });

    const numXMatches = xMatchIds.length;
    const numYMatches = yMatchIds.length;
    const matches: number[] = [];

    for (let i = 0; i < numXMatches; i += 1) {
      const xInfo = xMatchIds[i];
      const xId = xInfo[0];
      for (let j = 0; j < numYMatches; j += 1) {
        const yInfo = yMatchIds[j];
        const yId = yInfo[0];
        if (xId === yId) {
          // Check if it's the point is actually inside the circle:
          const centerX = xInfo[1];
          const centerY = yInfo[1];
          const radius = xInfo[2];
          const distanceToCenter = Math.sqrt(
            (untransformedX - centerX) ** 2 + (untransformedY - centerY) ** 2,
          );
          if (distanceToCenter < radius) {
            matches.push(xId);
          }
        }
      }
    }

    if (matches.length === 0) {
      return undefined;
    } else {
      return matches[matches.length - 1];
    }

  }
};

// Retrieve relate nodes for a given node. Retur empty array if not found.
const getArrayFromMap = (map: RelatedNodesMap, key: number | undefined): number[] => {
  if (key === undefined) {
    return [];
  } else {
    const retrieved = map[key];
    if (retrieved === undefined) {
      return [];
    } else {
      return retrieved;
    }
  }
};

interface IOutput {
  relatedToHovered: Set<number>;
  relatedToHighlightedOrSelected: Set<number>;
  selectedAndHighlighted: Set<number>;
}
export const getHighlightSets = (
    relatedNodesMap: RelatedNodesMap,
    highlightedProduct: number | undefined,
    selectedProducts: number[],
    hoveredProduct: number | undefined): IOutput => {

  const selectedAndHighlighted = [highlightedProduct, ...selectedProducts].filter(
    value => value !== undefined,
  ) as number[];

  const relatedToHovered: Set<number> = new Set(getArrayFromMap(relatedNodesMap, hoveredProduct));
  const relatedToHighlightedOrSelected = new Set(
    flatten(selectedAndHighlighted.map(nodeId => getArrayFromMap(relatedNodesMap, nodeId))),
  );
  return {
    relatedToHovered,
    relatedToHighlightedOrSelected,
    selectedAndHighlighted: new Set(selectedAndHighlighted),
  };
};

// Number of floats contained in the uniform block:
export const uniformBlockBufferSize = 16;

export const setTransformationMatrixInUniformBlock = (
    wrapper: PersistentFloat32Array, matrix: ITransformationMatrix,
  ) => {

  const actualData = wrapper.buffer;

  const std140Matrix = convertToStd140Matrix(matrix);
  for (let i = 0; i < numElementsStd140TransformMatrix; i += 1) {
    actualData[i] = std140Matrix[i];
  }
};

export const setCanvasSizeUniformBlock = (
    uniformBlockData: PersistentFloat32Array, width: number, height: number,
  ) => {
  const actualData = uniformBlockData.buffer;
  actualData[12] = width;
  actualData[13] = height;
};

export const setTweenProgresUniformBlock = (uniformBlockData: PersistentFloat32Array, tweenProgress: number) => {
  uniformBlockData.buffer[14] = tweenProgress;
};

export const updateTweenProgressInBuffer = (gl: WebGL2RenderingContext, tweenProgress: number) => {
  gl.bufferSubData(gl.UNIFORM_BUFFER, GL_NUM_BYTES_PER_FLOAT * 14, new Float32Array([tweenProgress]));
};
export const setDPRUniformBlock = (wrapper: PersistentFloat32Array, devicePixelRatio: number) => {
  wrapper.buffer[15] = devicePixelRatio;
};
