import {
  range,
} from 'lodash-es';
import debounce from 'lodash-es/debounce';
import React from 'react';
import styled from 'styled-components';
import {
  normalFontWeight,
} from '../../cssVariables';
import Tooltip from '../../viz/Tooltip';
import {
  defaultRCAThreshold,
} from '../../workerStore/network/Utils';

const inputId = 'product-space-rca-filter';
const delay = 350;

const ControlInnerContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Label = styled.label`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  font-size: 0.8rem;
  font-weight: 400;
  margin-bottom: 0.7rem;
`;

const TextInput = styled.input`
  width: 3rem;
  font-weight: ${normalFontWeight};
  color: #333;
  text-align: center;
`;

const DataList = styled.datalist`
  display: none;
`;
interface IProps {
  externalRcaThreshold: number;
  onRCAChange: (value: number) => void;

}
interface IState {
  rcaThreshold: number;
  isInputFocused: boolean;
}

export default class extends React.PureComponent<IProps, IState> {

  private textInputEl: HTMLInputElement | null = null;
  private rememberTextInput = (el: HTMLInputElement | null) => this.textInputEl = el;

  constructor(props: IProps) {
    super(props);
    this.state = {
      rcaThreshold: props.externalRcaThreshold,
      isInputFocused: false,
    };
  }

  private notifyParent = debounce(
    () => this.props.onRCAChange(this.state.rcaThreshold)
  , delay);

  componentDidUpdate(prevProps: IProps) {
    const nextProps = this.props;
    const textInputEl = this.textInputEl;
    if (nextProps !== prevProps && textInputEl !== null) {
      const inputFieldValue = parseFloat(textInputEl.value);
      if (inputFieldValue !== nextProps.externalRcaThreshold) {
        requestAnimationFrame(() => textInputEl.value = nextProps.externalRcaThreshold.toString());
      }
    }
  }

  private setRCAThreshold(rcaThreshold: number) {
    this.setState({rcaThreshold}, () => this.notifyParent());
  }
  private onChange = (event: React.FormEvent<any>) => {
    const rcaThreshold = event.currentTarget.value;
    this.setRCAThreshold(rcaThreshold);
  }

  private onFocus = () => {
    this.setState((prevState: IState): IState => ({...prevState, isInputFocused: true}));
}

  private onBlur = () => {
    const {textInputEl} = this;
    if (textInputEl !== null) {
      const value = parseFloat(textInputEl.value);
      const valueToSet = Number.isNaN(value) ? this.props.externalRcaThreshold : value;
      this.setState(
        (prevState: IState): IState => ({...prevState, rcaThreshold: valueToSet}),
        () => this.props.onRCAChange(valueToSet),
      );
    }
  }

  private resetRCAThreshold = () => this.setRCAThreshold(defaultRCAThreshold);
  render() {
    const {rcaThreshold} = this.state;
    const {externalRcaThreshold} = this.props;
    const rcaMin = 0;
    const rcaMax = 10;
    const rcaStep = 0.5;

    const hashmarksId = 'rca-filter-hashmarks';
    const dataList = [...range(rcaMin, rcaMax), rcaMax].map(value => (
      <option value={value} label={value.toString()} key={value}/>
    ));

    return (
      <>
        <Label htmlFor={inputId}>
          <div>
            {__lexiconText('rcaFilter.label')}
            <Tooltip
              explanation={__lexiconText('rcaFilter.tooltipText')}
            />
          </div>
        </Label>
        <div>
          <ControlInnerContainer>
            <input type='range' min={rcaMin} max={rcaMax} value={rcaThreshold}
              onDoubleClick={this.resetRCAThreshold} step={rcaStep}
              id={inputId} list={hashmarksId} onChange={this.onChange}/>

            <DataList id={hashmarksId}>
              {dataList}
            </DataList>
            <TextInput type='text'
              defaultValue={externalRcaThreshold.toString()}
              onFocus={this.onFocus}
              onBlur={this.onBlur}
              ref={this.rememberTextInput}/>
          </ControlInnerContainer>
        </div>
      </>
    );
  }
}
