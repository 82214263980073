import TimelineLite from 'gsap/TimelineLite';
import TweenLite from 'gsap/TweenLite';
import debounce from 'lodash-es/debounce';
import throttle from 'lodash-es/throttle';
import React from 'react';
import styled from 'styled-components';
import {
  ANGLEInstancedArrays,
  AttributeBufferRequestType,
  ColorQuadruplet,
  ColorTriplet,
  createPopulatedGLBuffer,
  EXT_disjoint_timer_query_webgl2,
  EXTDisjointTimerQuery,
  getProgramInfo,
  getUpdatePattern,
  GL_NUM_BYTES_PER_FLOAT,
  IAttributeRequest,
  IIndexRequest,
  IndexElementType,
  IProgramInfo,
  IUniformBlockRequest,
  IUpdatePatternItem,
  PersistentFloat32Array,
  resizeViewport,
  sendAttributesToGPUWithVAO,
  sendIndicesToGPUWithVAO,
  updateGLBuffer,
  updateUniformBuffer,
  WebGLVersion,
} from '../../sharedComponents/webglUtils';
import {
  failIfValidOrNonExhaustive,
  isWithinToleranceOf,
  millisecondsPerFrame,
  millisecondsPerSeconds,
} from '../../Utils';
import {
  isPanOutsideLimits,
  mouseDeltaToZoomFactorScale,
} from '../../viz/zoomPanUtils';
import {
  IProcessedEdge,
  IProcessedNode,
  RelatedNodesMap,
  xDomainWideningFactor,
  yDomainWideningFactor,
} from '../../workerStore/network/Utils';
import {
  convertToGLMatrix,
  getIdentityTransformMatrix,
  getScaleFactor,
  ITransformationMatrix,
  updatePanning,
  zoom,
} from '../panZoom';
import {
  animationDuration,
  controlButtonZoomFactor,
  regularEdgeWidth,
  relatedToHoveredEdgeWidth,
  relatedToSelectedHighlightedEdgeWidth,
} from '../Utils';
import {
  convertToInternalEdges,
  convertToInternalNodes,
  getIntervalTrees,
  INodeInternal,
  interpolateAnimation,
  lineIndices,
  lineIndicesCount,
  lineReferenceCoordValues,
  normalizedRegularEdgeColor,
  normalizedRelatedToHoveredEdgeColor,
  normalizedRelatedToSelectedOrHighlightedEdgeColor,
  numFloatsPerEdgeInstance,
  numFloatsPerNodeInstance,
  numInstancesPerNode,
  searchForHits,
  setCanvasSizeUniformBlock,
  setDPRUniformBlock,
  setTransformationMatrixInUniformBlock,
  setTweenProgresUniformBlock,
  uniformBlockBufferSize,
  updateTweenProgressInBuffer,
  visibleCircleIndices,
  visibleCircleThetaValues,
  visibleIsPointOnCircumferenceValues,
  writeToEdgeBuffers,
  writeToNodeBuffers,
} from './Utils';
const styles = require('../../sharedComponents/zoomPanControls.css');
import createIntervalTree, {
  IntervalTree,
} from 'interval-tree-1d';
import * as transformationMatrixJS from 'transformation-matrix-js';
import {
  sendHeroElementTiming,
} from '../../heroElement';
const {
  heroElementNames,
} = require('../../../buildConstants');

const durationInSeconds = animationDuration / 1000;

const hoverDetectionDebounceTime = 350; // in milliseconds
const hoverContinuousHitTestFrameRate = 30;

// The mouse needs to move by at least this number of pixels to be recognized
// as a drag event:
const mouseDragPixelsThreshold = 2;

// The duration between the mouse up and down event needs to be below this duration
// to be considered a click event:
const mouseClickMaxDuration = 250; // in milliseconds

// Only allow zooming 20 "clicks" of zooming in and 3 "clicks" of zooming out:
const maxZoomInIncrements = 20;
const maxZoomOutIncrements = 3;
// Limits for zoom factors for zooming in (max) and zooming out (min);
const maxZoomFactorLimit = controlButtonZoomFactor ** (maxZoomInIncrements + 1);
const minZoomFactorLimit = (1 / controlButtonZoomFactor) ** (maxZoomOutIncrements + 1);

const maxNumNodesAtAnyTime = 2600;

const maxNumEdgesAtAnyTime = 4500;

export interface INodeInternal {
  id: number;
  uniqueKey: string;
  centerX: number;
  centerY: number;
  radius: number;
  fillColor: ColorQuadruplet;
  strokeColor: ColorQuadruplet;
  strokeWidth: number;
}

enum AnimationStatus {
  Initial = 'Initial',
  InProgress = 'InProgress',
  TerminatedWhileInProgress = 'TerminatedWhileInProgress',
  FinishedCompletely = 'FinishedCompletely',
}

type Status = {
  status: AnimationStatus.Initial;
} | {
  status: AnimationStatus.FinishedCompletely;
  // Node related:
  currentNodes: Map<string, INodeInternal>;
  currentKeys: string[];
  setupVisibleDraw: (
    withInCSSPixels: number, heightInCSSPixels: number, transformationMatrix: ITransformationMatrix,
  ) => void;
  nodeIndicesCount: number;
  nodeInstancesCount: number;
  // Edge related:
  edgeIndicesCount: number;
  edgeInstancesCount: number;
  // Hit test related:
  xIntervalTree: IntervalTree<[number, number, number]>;
  yIntervalTree: IntervalTree<[number, number, number]>;
} | {
  status: AnimationStatus.InProgress;
  updatePattern: IUpdatePatternItem[];
  tweenTarget: {tweenProgress: number};
  prevNodes: Map<string, INodeInternal>;
  nextNodes: Map<string, INodeInternal>;
  timeline: gsap.Timeline;
  rafTimer: number;
  // Hit test related:
  xIntervalTree: IntervalTree<[number, number, number]>;
  yIntervalTree: IntervalTree<[number, number, number]>;
} | {
  status: AnimationStatus.TerminatedWhileInProgress;
  // Node-related:
  currentNodes: Map<string, INodeInternal>;
  currentKeys: string[];
  // Hit test related:
  xIntervalTree: IntervalTree<[number, number, number]>;
  yIntervalTree: IntervalTree<[number, number, number]>;
};

interface RGBColor {
  R: number;
  G: number;
  B: number;
}

interface IProps {
  nodeList: IProcessedNode[];
  edgeList: IProcessedEdge[];
  relatedNodesMap: RelatedNodesMap;
  selectedProducts: number[];
  highlightedProduct: number | undefined;
  hoveredProduct: number | undefined;
  chartContainerWidth: number | undefined;
  chartContainerHeight: number | undefined;

  // Pass the root element of this component to the parent for DOM measurement
  // on window resizing:
  saveRootEl: (el: HTMLElement | null) => void;
  onNodeClick: (id: number) => void;
  onNodeDoubleClick: () => void;
  onNodeMouseEnter: (id: number) => void;
  onNodeMouseLeave: () => void;
  updateTransformationMatrix: (matrix: ITransformationMatrix) => void;

  showZoomButtons: boolean;
  stringToColorTriplet: (colorString: string) => ColorTriplet;
  backgroundColor: RGBColor;
}

type IGLInfo = {
  // Node-related info:
  nodeVAO: WebGLVertexArrayObjectOES;
  nodesData: PersistentFloat32Array;
  nodesBuffer: WebGLBuffer;
  // Edge-related info:
  edgeVAO: WebGLVertexArrayObjectOES;
  edgesData: PersistentFloat32Array;
  edgesBuffer: WebGLBuffer;
} & (
  {
    version: WebGLVersion.One;
    gl: WebGLRenderingContext;
    vaoExtension: OES_vertex_array_object;
    instancedDrawingExtension: ANGLEInstancedArrays
    timerExtension: EXTDisjointTimerQuery | null;
  } | {
    version: WebGLVersion.Two;
    gl: WebGL2RenderingContext;
    timerExtension: EXT_disjoint_timer_query_webgl2 | null;
    // UBO-related info:
    uniformBlockBuffer: WebGLBuffer;
    uniformBlockData: PersistentFloat32Array;
  }
);

const Root = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 1px solid white;
  contain: content;
  background-color: black;
`;

const Canvas = styled.canvas`
  width: 100%;
  height: 100%;
`;

const assignedUniformBlockIndex = 0;

export default class extends React.Component<IProps> {
  private rememberRootEl = (el: HTMLElement | null) => this.props.saveRootEl(el);
  private canvas: HTMLCanvasElement | null = null;
  private rememberCanvas = (el: HTMLCanvasElement | null) => this.canvas = el;

  private glInfo: IGLInfo | undefined;

  private nodeProgram: IProgramInfo | undefined;
  private edgeProgram: IProgramInfo | undefined;

  private status: Status = {
    status: AnimationStatus.Initial,
  };

  private transformationMatrix: ITransformationMatrix = getIdentityTransformMatrix();

  private setupWebGL() {
    const {canvas} = this;
    if (canvas !== null) {
      const getNodesAttributesRequest = (inputNodesBuffer: WebGLBuffer): IAttributeRequest[] => ([{
          name: 'isPointOnCircumference',
          numFloatsPerVertex: 1,
          buffer: {
            type: AttributeBufferRequestType.Implicit,
            totalSizeAsNumOfFloats: visibleIsPointOnCircumferenceValues.length,
          },
          stride: 0, offset: 0,
          isInstanced: false,
        }, {
          name: 'theta',
          numFloatsPerVertex: 1,
          buffer: {
            type: AttributeBufferRequestType.Implicit,
            totalSizeAsNumOfFloats: visibleCircleThetaValues.length,
          },
          stride: 0, offset: 0,
          isInstanced: false,
        }, {
          name: 'initialCenter',
          numFloatsPerVertex: 2,
          buffer: {type: AttributeBufferRequestType.Explicit, buffer: inputNodesBuffer},
          stride: numFloatsPerNodeInstance * GL_NUM_BYTES_PER_FLOAT,
          offset: 0,
          isInstanced: true,
        }, {
          name: 'finalCenter',
          numFloatsPerVertex: 2,
          buffer: {type: AttributeBufferRequestType.Explicit, buffer: inputNodesBuffer},
          stride: numFloatsPerNodeInstance * GL_NUM_BYTES_PER_FLOAT,
          offset: 2 * GL_NUM_BYTES_PER_FLOAT,
          isInstanced: true,
        }, {
          name: 'initialRadius',
          numFloatsPerVertex: 1,
          buffer: {type: AttributeBufferRequestType.Explicit, buffer: inputNodesBuffer},
          stride: numFloatsPerNodeInstance * GL_NUM_BYTES_PER_FLOAT,
          offset: 4 * GL_NUM_BYTES_PER_FLOAT,
          isInstanced: true,
        }, {
          name: 'finalRadius',
          numFloatsPerVertex: 1,
          buffer: {type: AttributeBufferRequestType.Explicit, buffer: inputNodesBuffer},
          stride: numFloatsPerNodeInstance * GL_NUM_BYTES_PER_FLOAT,
          offset: 5 * GL_NUM_BYTES_PER_FLOAT,
          isInstanced: true,
        }, {
          name: 'initialHalfStrokeWidth',
          numFloatsPerVertex: 1,
          buffer: {type: AttributeBufferRequestType.Explicit, buffer: inputNodesBuffer},
          stride: numFloatsPerNodeInstance * GL_NUM_BYTES_PER_FLOAT,
          offset: 6 * GL_NUM_BYTES_PER_FLOAT,
          isInstanced: true,
        }, {
          name: 'finalHalfStrokeWidth',
          numFloatsPerVertex: 1,
          buffer: {type: AttributeBufferRequestType.Explicit, buffer: inputNodesBuffer},
          stride: numFloatsPerNodeInstance * GL_NUM_BYTES_PER_FLOAT,
          offset: 7 * GL_NUM_BYTES_PER_FLOAT,
          isInstanced: true,
        }, {
          name: 'initialColor',
          numFloatsPerVertex: 4,
          buffer: {type: AttributeBufferRequestType.Explicit, buffer: inputNodesBuffer},
          stride: numFloatsPerNodeInstance * GL_NUM_BYTES_PER_FLOAT,
          offset: 8 * GL_NUM_BYTES_PER_FLOAT,
          isInstanced: true,
        }, {
          name: 'finalColor',
          numFloatsPerVertex: 4,
          buffer: {type: AttributeBufferRequestType.Explicit, buffer: inputNodesBuffer},
          stride: numFloatsPerNodeInstance * GL_NUM_BYTES_PER_FLOAT,
          offset: 12 * GL_NUM_BYTES_PER_FLOAT,
          isInstanced: true,
        }, {
          name: 'strokeAdjustmentFactor',
          numFloatsPerVertex: 1,
          buffer: {type: AttributeBufferRequestType.Explicit, buffer: inputNodesBuffer},
          stride: numFloatsPerNodeInstance * GL_NUM_BYTES_PER_FLOAT,
          offset: 16 * GL_NUM_BYTES_PER_FLOAT,
          isInstanced: true,
      }]);
      const nodesData = new PersistentFloat32Array(
        maxNumNodesAtAnyTime * numInstancesPerNode * numFloatsPerNodeInstance,
      );
      const nodesIndexRequest: IIndexRequest = {
        elementType: IndexElementType.Int16, totalSizeAsNumOfInts: visibleCircleIndices.length,
      };

      const getEdgesAttributeRequest = (inputEdgesBuffer: WebGLBuffer): IAttributeRequest[] => ([{
        name: 'referenceCoord',
        numFloatsPerVertex: 2,
        buffer: {type: AttributeBufferRequestType.Implicit, totalSizeAsNumOfFloats: lineReferenceCoordValues.length},
        stride: 0, offset: 0,
        isInstanced: false,
      }, {
        name: 'lineStart',
        numFloatsPerVertex: 2,
        buffer: {type: AttributeBufferRequestType.Explicit, buffer: inputEdgesBuffer},
        stride: numFloatsPerEdgeInstance * GL_NUM_BYTES_PER_FLOAT,
        offset: 0 * GL_NUM_BYTES_PER_FLOAT,
        isInstanced: true,
      }, {
        name: 'lineEnd',
        numFloatsPerVertex: 2,
        buffer: {type: AttributeBufferRequestType.Explicit, buffer: inputEdgesBuffer},
        stride: numFloatsPerEdgeInstance * GL_NUM_BYTES_PER_FLOAT,
        offset: 2 * GL_NUM_BYTES_PER_FLOAT,
        isInstanced: true,
      }, {
        name: 'thickness',
        numFloatsPerVertex: 1,
        buffer: {type: AttributeBufferRequestType.Explicit, buffer: inputEdgesBuffer},
        stride: numFloatsPerEdgeInstance * GL_NUM_BYTES_PER_FLOAT,
        offset: 4 * GL_NUM_BYTES_PER_FLOAT,
        isInstanced: true,
      }, {
        name: 'color',
        numFloatsPerVertex: 4,
        buffer: {type: AttributeBufferRequestType.Explicit, buffer: inputEdgesBuffer},
        stride: numFloatsPerEdgeInstance * GL_NUM_BYTES_PER_FLOAT,
        offset: 5 * GL_NUM_BYTES_PER_FLOAT,
        isInstanced: true,
      }, {
        name: 'dashedLineSolidSegmentLength',
        numFloatsPerVertex: 1,
        buffer: {type: AttributeBufferRequestType.Explicit, buffer: inputEdgesBuffer},
        stride: numFloatsPerEdgeInstance * GL_NUM_BYTES_PER_FLOAT,
        offset: 9 * GL_NUM_BYTES_PER_FLOAT,
        isInstanced: true,
      }]);
      const edgesIndexRequest: IIndexRequest = {
        elementType: IndexElementType.Int16, totalSizeAsNumOfInts: lineIndices.length,
      };
      const edgesData = new PersistentFloat32Array(maxNumEdgesAtAnyTime * numFloatsPerEdgeInstance);

      // Try WebGL 2 first:
      const gl2 = canvas.getContext('webgl2', {alpha: false});
      if (gl2 !== null) {
        const nodeVAO = gl2.createVertexArray();
        if (nodeVAO === null) {
          throw new Error('Unable to create VAO for nodes');
        }
        const {buffer: nodesBuffer} = createPopulatedGLBuffer(gl2, nodesData);

        const uniformBlockData = new PersistentFloat32Array(uniformBlockBufferSize);
        const {buffer: uniformBlockBuffer} = createPopulatedGLBuffer(gl2, uniformBlockData);

        let timerExtension: EXT_disjoint_timer_query_webgl2 | null = null;
        if (process.env.NODE_ENV !== 'production') {
          timerExtension = gl2.getExtension('EXT_disjoint_timer_query_webgl2');
        }

        const sharedUniformBlockRequest: IUniformBlockRequest = {
          name: 'uniformBlock',
          assignedBlockIndex: assignedUniformBlockIndex,
          assignedBuffer: uniformBlockBuffer,
        };

        this.nodeProgram = getProgramInfo({
          version: WebGLVersion.Two,
          gl: gl2,
          vertexShader: require('./2Node.vert'),
          fragmentShader: require('./2SimpleFourColorPassThrough.frag'),
          attributes: getNodesAttributesRequest(nodesBuffer),
          indexBuffer: nodesIndexRequest,
          vaoObject: nodeVAO,
          uniforms: [],
          uniformBlocks: [sharedUniformBlockRequest],
        });

        const {buffer: edgesBuffer} = createPopulatedGLBuffer(gl2, edgesData);
        const edgeVAO = gl2.createVertexArray();
        if (edgeVAO === null) {
          throw new Error('Unable to create VAO for edges');
        }

        this.edgeProgram = getProgramInfo({
          version: WebGLVersion.Two,
          gl: gl2,
          vertexShader: require('./2Edge.vert'),
          fragmentShader: require('./2Edge.frag'),
          attributes: getEdgesAttributeRequest(edgesBuffer),
          indexBuffer: edgesIndexRequest,
          vaoObject: edgeVAO,
          uniforms: [],
          uniformBlocks: [sharedUniformBlockRequest],
        });

        this.glInfo = {
          version: WebGLVersion.Two,
          gl: gl2,
          nodeVAO, nodesData, nodesBuffer,
          edgeVAO, edgesData, edgesBuffer,
          uniformBlockBuffer, uniformBlockData,
          timerExtension,
        };

      } else {
        // Then fall back to WebGL 1:
        const gl1 = canvas.getContext('webgl', {alpha: false});
        if (gl1 === null) {
          console.warn('WebGL not available');
          this.glInfo = undefined;
        } else {
          const vaoExtension = gl1.getExtension('OES_vertex_array_object');
          const instancedDrawingExtension = gl1.getExtension('ANGLE_instanced_arrays');

          let timerExtension: EXTDisjointTimerQuery | null = null;
          if (process.env.NODE_ENV !== 'production') {
            timerExtension = gl1.getExtension('EXT_disjoint_timer_query');
          }

          if (!vaoExtension || !instancedDrawingExtension) {
            if (!vaoExtension) {
              console.warn('OES_vertex_array_object extension not available.');
            }
            if (!instancedDrawingExtension) {
              console.warn('ANGLE_instanced_arrays extension not available');
            }
            this.glInfo = undefined;
          } else {
            const nodeVAO = vaoExtension.createVertexArrayOES();
            const edgeVAO = vaoExtension.createVertexArrayOES();
            if (nodeVAO === null) {
              throw new Error('Unable to create VAO for nodes');
            }
            if (edgeVAO === null) {
              throw new Error('Unable to create VAO for edges');
            }

            const {buffer: nodesBuffer} = createPopulatedGLBuffer(gl1, nodesData);
            this.nodeProgram = getProgramInfo({
              version: WebGLVersion.One,
              gl: gl1,
              vertexShader: require('./node.vert'),
              fragmentShader: require('./simpleFourColorPassThrough.frag'),
              attributes: getNodesAttributesRequest(nodesBuffer),
              indexBuffer: nodesIndexRequest,
              vaoObject: nodeVAO,
              vaoExtension,
              instancedDrawingExtension,
              uniforms: ['transform', 'canvasSize', 'tweenProgress'],
            });

            const {buffer: edgesBuffer} = createPopulatedGLBuffer(gl1, edgesData);
            this.edgeProgram = getProgramInfo({
              version: WebGLVersion.One,
              gl: gl1,
              vertexShader: require('./edge.vert'),
              fragmentShader: require('./edge.frag'),
              attributes: getEdgesAttributeRequest(edgesBuffer),
              indexBuffer: edgesIndexRequest,
              vaoObject: edgeVAO,
              vaoExtension,
              instancedDrawingExtension,
              uniforms: ['canvasSize', 'transform', 'devicePixelRatio'],
            });

            this.glInfo = {
              version: WebGLVersion.One,
              gl: gl1,
              vaoExtension, timerExtension, instancedDrawingExtension,
              nodeVAO, nodesData, nodesBuffer,
              edgeVAO, edgesData, edgesBuffer,
            };
          }
        }
      }
    }
  }

  private performTransition(prevStatus: Status, nextProps: IProps, prevProps: IProps) {
    const {
      glInfo, nodeProgram, edgeProgram,
      widthInCSSPixels, heightInCSSPixels, transformationMatrix,
    } = this;

    if (glInfo !== undefined &&
        nodeProgram !== undefined && edgeProgram !== undefined &&
        widthInCSSPixels !== undefined && heightInCSSPixels !== undefined) {

      const {
        gl,
        nodesBuffer, nodesData, nodeVAO,
        edgesBuffer, edgesData, edgeVAO,
      } = glInfo;

      if (prevStatus.status === AnimationStatus.FinishedCompletely ||
          prevStatus.status === AnimationStatus.TerminatedWhileInProgress ||
          prevStatus.status === AnimationStatus.Initial) {

        let prevNodes: Map<string, INodeInternal>, prevKeys: string[];
        if (prevStatus.status === AnimationStatus.FinishedCompletely ||
            prevStatus.status === AnimationStatus.TerminatedWhileInProgress) {

          prevNodes = prevStatus.currentNodes;
          prevKeys = prevStatus.currentKeys;
        } else if (prevStatus.status === AnimationStatus.Initial) {
          prevNodes = new Map();
          prevKeys = [];

          if (glInfo.version === WebGLVersion.One) {
            glInfo.vaoExtension.bindVertexArrayOES(nodeVAO);
          } else {
            glInfo.gl.bindVertexArray(nodeVAO);
          }

          sendAttributesToGPUWithVAO({
            gl, programInfo: nodeProgram,
            attributeName: 'isPointOnCircumference',
            data: new Float32Array(visibleIsPointOnCircumferenceValues),
          });
          sendAttributesToGPUWithVAO({
            gl, programInfo: nodeProgram,
            attributeName: 'theta',
            data: new Float32Array(visibleCircleThetaValues),
          });
          sendIndicesToGPUWithVAO({
            gl, programInfo: nodeProgram, data: new Uint16Array(visibleCircleIndices),
          });

          if (glInfo.version === WebGLVersion.One) {
            glInfo.vaoExtension.bindVertexArrayOES(edgeVAO);
          } else {
            glInfo.gl.bindVertexArray(edgeVAO);
          }

          sendAttributesToGPUWithVAO({
            gl, programInfo: edgeProgram,
            attributeName: 'referenceCoord',
            data: new Float32Array(lineReferenceCoordValues),
          });
          sendIndicesToGPUWithVAO({
            gl, programInfo: edgeProgram, data: new Uint16Array(lineIndices),
          });

          if (glInfo.version === WebGLVersion.One) {
            glInfo.vaoExtension.bindVertexArrayOES(null as any);
          } else {
            glInfo.gl.bindVertexArray(null as any);
          }

          const { backgroundColor: bg } = prevProps;

          gl.disable(gl.DEPTH_TEST);
          gl.enable(gl.BLEND);
          gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA);
          gl.clearColor(bg.R / 255, bg.G / 255, bg.B / 255, 1);

        } else {
          failIfValidOrNonExhaustive(prevStatus, 'Invalid update type');
          // These lines will never run:
          prevNodes = new Map();
          prevKeys = [];
        }

        const {
          nodeList, edgeList, relatedNodesMap, highlightedProduct, selectedProducts, hoveredProduct,
          backgroundColor,
        } = nextProps;

        const {nextNodes, nextKeys} = convertToInternalNodes(
          nodeList, relatedNodesMap, highlightedProduct, selectedProducts,
          hoveredProduct, nextProps.stringToColorTriplet,
        );

        const canvasLargerSide = Math.max(widthInCSSPixels, heightInCSSPixels);
        const {
          nextKeys: nextEdgeKeys, nextEdges,
        } = convertToInternalEdges({
          edgeList, relatedNodesMap, highlightedProduct, selectedProducts, hoveredProduct, canvasLargerSide,
          regularEdgeColor: normalizedRegularEdgeColor,
          relatedToHoveredEdgeColor: normalizedRelatedToHoveredEdgeColor,
          relatedToSelectedOrHighlightedEdgeColor: normalizedRelatedToSelectedOrHighlightedEdgeColor,
          relatedToHoveredEdgeWidth,
          relatedToSelectedHighlightedEdgeWidth,
          regularEdgeWidth,
        });

        const updatePattern = getUpdatePattern(prevKeys, nextKeys);
        const nodeInfo = writeToNodeBuffers(prevNodes, nextNodes, updatePattern, nodesData);
        const edgeInfo = writeToEdgeBuffers(nextEdgeKeys, nextEdges, edgesData);

        updateGLBuffer({gl, data: nodesData.getMeaningfulData(), buffer: nodesBuffer});
        updateGLBuffer({gl, data: edgesData.getMeaningfulData(), buffer: edgesBuffer});

        const doesNextUpdateHaveData = (nextProps.nodeList.length > 0) && (nextProps.edgeList.length > 0);

        let nextXIntervalTree: IntervalTree<[number, number, number]>;
        let nextYIntervalTree: IntervalTree<[number, number, number]>;
        if ((doesNextUpdateHaveData &&
              (nextProps.nodeList !== prevProps.nodeList || nextProps.edgeList !== prevProps.edgeList)) ||
            prevStatus.status === AnimationStatus.Initial) {

          const intervalTrees = getIntervalTrees(nextKeys, nextNodes, createIntervalTree);
          nextXIntervalTree = intervalTrees.xIntervalTree;
          nextYIntervalTree = intervalTrees.yIntervalTree;
        } else {
          nextXIntervalTree = prevStatus.xIntervalTree;
          nextYIntervalTree = prevStatus.yIntervalTree;
        }

        const onAnimationStart = (
            widthInCSSPixelsInput: number,
            heightInCSSPixelsInput: number,
            transformationMatrixInput: ITransformationMatrix,
          ) => {

          if (glInfo.version === WebGLVersion.One) {
            const glMatrix = convertToGLMatrix(transformationMatrixInput);

            gl.useProgram(edgeProgram.program);
            gl.uniform2f(edgeProgram.uniforms.canvasSize, widthInCSSPixelsInput, heightInCSSPixelsInput);
            gl.uniformMatrix3fv(edgeProgram.uniforms.transform, false, glMatrix);
            gl.uniform1f(edgeProgram.uniforms.devicePixelRatio, window.devicePixelRatio);

            gl.useProgram(nodeProgram.program);
            gl.uniform2f(nodeProgram.uniforms.canvasSize, widthInCSSPixelsInput, heightInCSSPixelsInput);
            gl.uniformMatrix3fv(nodeProgram.uniforms.transform, false, glMatrix);
          } else {
            const uniformBlockData = glInfo.uniformBlockData;
            const uniformBlockBuffer = glInfo.uniformBlockBuffer;

            setTransformationMatrixInUniformBlock(uniformBlockData, transformationMatrixInput);
            setCanvasSizeUniformBlock(uniformBlockData, widthInCSSPixelsInput, heightInCSSPixelsInput);
            setDPRUniformBlock(uniformBlockData, devicePixelRatio);
            updateUniformBuffer({gl: glInfo.gl, data: uniformBlockData.buffer, buffer: uniformBlockBuffer});
            glInfo.gl.bindBuffer(glInfo.gl.UNIFORM_BUFFER, uniformBlockBuffer);
          }
        };

        const tweenTarget = {tweenProgress: 0};

        const onAnimationUpdate = () => {
          const tweenProgress = tweenTarget.tweenProgress;
          // tslint:disable-next-line:no-bitwise
          gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);

          if (glInfo.version === WebGLVersion.One) {
            const vaoExtension = glInfo.vaoExtension;
            gl.useProgram(edgeProgram.program);
            vaoExtension.bindVertexArrayOES(edgeVAO);
            glInfo.instancedDrawingExtension.drawElementsInstancedANGLE(
              gl.TRIANGLES, lineIndicesCount, edgeProgram.indexBuffer.elementType, 0, edgeInfo.instanceCount,
            );
            gl.useProgram(nodeProgram.program);
            gl.uniform1f(nodeProgram.uniforms.tweenProgress, tweenProgress);
            vaoExtension.bindVertexArrayOES(nodeVAO);
            glInfo.instancedDrawingExtension.drawElementsInstancedANGLE(
              gl.TRIANGLES, visibleCircleIndices.length,
                nodeProgram.indexBuffer.elementType, 0, nodeInfo.instanceCount,
            );
          } else {
            const gl2 = glInfo.gl;
            updateTweenProgressInBuffer(gl2, tweenProgress);
            gl2.useProgram(edgeProgram.program);
            gl2.bindVertexArray(edgeVAO);
            gl2.drawElementsInstanced(
                gl2.TRIANGLES, lineIndicesCount, edgeProgram.indexBuffer.elementType, 0, edgeInfo.instanceCount,
              );
            gl2.useProgram(nodeProgram.program);
            gl2.bindVertexArray(nodeVAO);
            gl2.drawElementsInstanced(
              gl2.TRIANGLES, visibleCircleIndices.length,
                nodeProgram.indexBuffer.elementType, 0, nodeInfo.instanceCount,
            );
          }
        };

        const onAnimationComplete = () => {
          if (glInfo.version === WebGLVersion.One) {
            glInfo.vaoExtension.bindVertexArrayOES(null as any);
          } else {
            glInfo.gl.bindVertexArray(null as any);
          }

          this.status = {
            status: AnimationStatus.FinishedCompletely,
            // Node related:
            currentNodes: nextNodes,
            currentKeys: nextKeys,
            setupVisibleDraw: onAnimationStart,
            nodeIndicesCount: visibleCircleIndices.length,
            nodeInstancesCount: nodeInfo.instanceCount,
            // Edge related:
            edgeIndicesCount: lineIndicesCount,
            edgeInstancesCount: edgeInfo.instanceCount,
            // Hit test related:
            xIntervalTree: nextXIntervalTree,
            yIntervalTree: nextYIntervalTree,
          };
        };

        const timeline = new TimelineLite({paused: true});
        const animationTween = TweenLite.to(tweenTarget, durationInSeconds, {
          tweenProgress: 1,
          ease: 'Cubic.easeOut',
          onUpdate: onAnimationUpdate,
          onComplete: onAnimationComplete,
        });
        timeline.add(animationTween);

        onAnimationStart(widthInCSSPixels, heightInCSSPixels, transformationMatrix);
        if (doesNextUpdateHaveData) {
          const rafTimer = requestAnimationFrame(() => {
            timeline.play();
          });
          this.status = {
            status: AnimationStatus.InProgress,
            updatePattern,
            tweenTarget,
            prevNodes, nextNodes,
            timeline,
            rafTimer,
            xIntervalTree: nextXIntervalTree,
            yIntervalTree: nextYIntervalTree,
          };
        } else {
          gl.clearColor(backgroundColor.R / 255, backgroundColor.G / 255, backgroundColor.B / 255, 1);
          // tslint:disable-next-line:no-bitwise
          gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);
          onAnimationComplete();
        }
      }
    }
  }

  private widthInCSSPixels: number | undefined;
  private heightInCSSPixels: number | undefined;

  private setWidthHeightInCSSPixels = () => {
    const {canvas} = this;
    if (canvas !== null) {
      const {width, height} = canvas.getBoundingClientRect();
      this.widthInCSSPixels = width;
      this.heightInCSSPixels = height;
    }
  }

  private reportHeroElementTimingIfNeeded(props: IProps) {
    if (props.chartContainerHeight !== undefined &&
        props.chartContainerWidth !== undefined &&
        props.nodeList.length > 0) {
      sendHeroElementTiming(heroElementNames.productSpace);
    }
  }

  componentDidMount() {
    const props = this.props;

    this.setupWebGL();
    const {glInfo} = this;
    if (props.chartContainerWidth !== undefined && props.chartContainerHeight !== undefined && glInfo !== undefined) {
      const {gl} = glInfo;
      this.setWidthHeightInCSSPixels();
      resizeViewport(gl, true);
      this.terminateTransitionInProgress();
      this.performTransition(this.status, props, props);
      this.reportHeroElementTimingIfNeeded(props);
    }

  }

  componentWillUnmount() {
    const {glInfo} = this;
    if (glInfo !== undefined) {
      this.terminateTransitionInProgress();
      const {
        gl, nodesBuffer, edgesBuffer,
      } = glInfo;

      gl.deleteBuffer(nodesBuffer);
      gl.deleteBuffer(edgesBuffer);
      glInfo.nodesData = undefined as any;
      glInfo.edgesData = undefined as any;
    }
  }

  componentDidUpdate(prevProps: IProps) {
    const nextProps = this.props;

    const {glInfo} = this;
    if (nextProps !== prevProps && glInfo !== undefined) {
      if ((nextProps.chartContainerHeight !== prevProps.chartContainerHeight ||
          nextProps.chartContainerWidth !== prevProps.chartContainerWidth) &&
          (nextProps.chartContainerHeight !== undefined &&
            nextProps.chartContainerWidth !== undefined)) {

        const {gl} = glInfo;
        this.setWidthHeightInCSSPixels();
        resizeViewport(gl, true);
      }

      if (prevProps.showZoomButtons !== nextProps.showZoomButtons) {
        // Force reset the transformoation matrix when switching between
        // zoom/pan enabled and disabled states because we want to ensure that
        // going from zoom enabled to disabled, the graph appears at the normal
        // zoom level so that the sector labels can point to the right nodes.
        this.transformationMatrix = getIdentityTransformMatrix();
        this.renderVisibleBufferWithTransformation();
      }

      if (nextProps.nodeList !== prevProps.nodeList ||
          nextProps.relatedNodesMap !== prevProps.relatedNodesMap ||
          nextProps.selectedProducts !== prevProps.selectedProducts ||
          nextProps.highlightedProduct !== prevProps.highlightedProduct ||
          nextProps.hoveredProduct !== prevProps.hoveredProduct) {

        this.terminateTransitionInProgress();
        this.performTransition(this.status, nextProps, prevProps);
      }
      this.reportHeroElementTimingIfNeeded(nextProps);
    }
  }

  private terminateTransitionInProgress() {
    const {status: prevStatus} = this;
    if (prevStatus.status === AnimationStatus.InProgress) {
      const {
        updatePattern, tweenTarget: {tweenProgress}, timeline,
        prevNodes, nextNodes, rafTimer,
        xIntervalTree, yIntervalTree,
      } = prevStatus;

      cancelAnimationFrame(rafTimer);
      (timeline as any).stop();

      const {
        keysAfterTermination,
        nodesMapAfterTermination,
      } = interpolateAnimation(updatePattern, prevNodes, nextNodes, tweenProgress);

      this.status = {
        status: AnimationStatus.TerminatedWhileInProgress,
        // Node-related:
        currentNodes: nodesMapAfterTermination,
        currentKeys: keysAfterTermination,
        // Hit test related:
        xIntervalTree, yIntervalTree,
      };
    }
  }

  private renderVisibleBufferWithTransformation() {
    const {
      status, glInfo, nodeProgram, widthInCSSPixels, heightInCSSPixels,
      transformationMatrix, edgeProgram,
    } = this;
    if (glInfo !== undefined && nodeProgram !== undefined &&
        edgeProgram !== undefined &&
        widthInCSSPixels !== undefined && heightInCSSPixels !== undefined) {
      if (status.status === AnimationStatus.FinishedCompletely) {
        const {gl, nodeVAO, edgeVAO} = glInfo;

        const {
          nodeIndicesCount, nodeInstancesCount,
          edgeIndicesCount, edgeInstancesCount,
        } = status;
        const tweenProgress = 1;

        // tslint:disable-next-line:no-bitwise
        gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);

        const glMatrix = convertToGLMatrix(transformationMatrix);

        if (glInfo.version === WebGLVersion.One) {
          gl.useProgram(edgeProgram.program);
          gl.uniformMatrix3fv(edgeProgram.uniforms.transform, false, glMatrix);
          gl.uniform1f(edgeProgram.uniforms.devicePixelRatio, window.devicePixelRatio);

          const vaoExtension = glInfo.vaoExtension;
          vaoExtension.bindVertexArrayOES(edgeVAO);
          glInfo.instancedDrawingExtension.drawElementsInstancedANGLE(
            gl.TRIANGLES, edgeIndicesCount, gl.UNSIGNED_SHORT, 0, edgeInstancesCount,
          );

          gl.useProgram(nodeProgram.program);
          gl.uniformMatrix3fv(nodeProgram.uniforms.transform, false, glMatrix);
          gl.uniform1f(nodeProgram.uniforms.tweenProgress, tweenProgress);
          vaoExtension.bindVertexArrayOES(nodeVAO);
          glInfo.instancedDrawingExtension.drawElementsInstancedANGLE(
            gl.TRIANGLES, nodeIndicesCount, gl.UNSIGNED_SHORT, 0, nodeInstancesCount,
          );
        } else {
          const gl2 = glInfo.gl;
          const uniformBlockData = glInfo.uniformBlockData;
          const uniformBlockBuffer = glInfo.uniformBlockBuffer;

          setTransformationMatrixInUniformBlock(uniformBlockData, transformationMatrix);
          setTweenProgresUniformBlock(uniformBlockData, tweenProgress);
          updateUniformBuffer({gl: gl2, data: uniformBlockData.buffer, buffer: uniformBlockBuffer});

          gl2.useProgram(edgeProgram.program);
          gl2.bindVertexArray(edgeVAO);
          gl2.drawElementsInstanced(
            gl.TRIANGLES, lineIndicesCount, edgeProgram.indexBuffer.elementType, 0, edgeInstancesCount,
          );
          gl2.useProgram(nodeProgram.program);
          gl2.bindVertexArray(nodeVAO);
          gl2.drawElementsInstanced(
            gl.TRIANGLES, nodeIndicesCount, gl.UNSIGNED_SHORT, 0, nodeInstancesCount,
          );
        }
      }
    }
  }

  private isMouseInsideCanvas: boolean = false;
  private onMouseEnter = () => this.isMouseInsideCanvas = true;
  private onMouseLeave = () => {
    this.isMouseInsideCanvas = false;
    this._debouncedHover.cancel();
    this._throttledHover.cancel();
    this.props.onNodeMouseLeave();
  }

  private mouseClientX: number | undefined;
  private mouseClientY: number | undefined;
  private mousePageX: number | undefined;
  private mousePageY: number | undefined;
  private mouseButtons: number | undefined;
  private isMouseDown: boolean = false;

  // Values at the start of the zoom/pan sequence:
  private transformationMatrixAtMouseDown: ITransformationMatrix | undefined;
  private mouseDownPageX: number | undefined;
  private mouseDownPageY: number | undefined;
  private mouseDownClientX: number | undefined;
  private mouseDownClientY: number | undefined;
  private mouseDownStartTime: number | undefined;
  private mouseDownTopOffset: number | undefined;
  private mouseDownLeftOffset: number | undefined;

  private doubleClickDetectionTimer: number | undefined;

  private hoveredNode: number | undefined;
  private performHover = () => {
    const {status} = this;
    if (status.status === AnimationStatus.FinishedCompletely ||
        status.status === AnimationStatus.InProgress) {

      const prevHoveredNode = this.hoveredNode;
      // const result = this.performHitTest();
      const result = this.performHitTest(this.mouseClientX, this.mouseClientY);
      let nextHoveredNode: number | undefined;
      if (result !== undefined) {
        nextHoveredNode = result;
      } else {
        nextHoveredNode = undefined;
      }
      this.hoveredNode = nextHoveredNode;

      if (nextHoveredNode !== prevHoveredNode) {
        if (prevHoveredNode === undefined && nextHoveredNode !== undefined) {
          this.props.onNodeMouseEnter(nextHoveredNode);
        } else if (prevHoveredNode !== undefined && nextHoveredNode === undefined) {
          this.props.onNodeMouseLeave();
        } else if (prevHoveredNode !== undefined && nextHoveredNode !== undefined) {
          this.props.onNodeMouseEnter(nextHoveredNode);
        }
      }
    }
  }

  private _debouncedHover = debounce(this.performHover, hoverDetectionDebounceTime);
  private _throttledHover = throttle(
    this.performHover, millisecondsPerSeconds / hoverContinuousHitTestFrameRate, {leading: true},
  );

  private _batchPan = throttle(() => {
    const {
      mouseDownPageX, mouseDownPageY, mouseDownClientX, mouseDownClientY,
      mousePageX, mousePageY, mouseButtons, canvas,
      mouseClientX, mouseClientY, transformationMatrixAtMouseDown,
      mouseDownTopOffset, mouseDownLeftOffset,
      widthInCSSPixels, heightInCSSPixels, isMouseInsideCanvas,
    } = this;
    if (mouseDownPageX !== undefined && mouseDownPageY !== undefined &&
        mouseDownClientX !== undefined && mouseDownClientY !== undefined &&
        mouseClientX !== undefined && mouseClientY !== undefined &&
        transformationMatrixAtMouseDown !== undefined &&
        mouseDownTopOffset !== undefined && mouseDownLeftOffset !== undefined &&
        widthInCSSPixels !== undefined && heightInCSSPixels !== undefined &&
        mousePageX !== undefined && mousePageY !== undefined && canvas !== null) {

      if (mouseButtons === 0 || isMouseInsideCanvas === false) {
        // Stop panning when mouse leaves the SVG area:
        this._cleanUpMouseDown();

      } else {
        // Check to see if mouse has moved enough to qualify as a drag event:
        if ((!isWithinToleranceOf(mousePageX, mouseDownPageX, mouseDragPixelsThreshold) ||
            !isWithinToleranceOf(mousePageY, mouseDownPageY, mouseDragPixelsThreshold))) {

          const relativeX = mouseClientX - mouseDownLeftOffset;
          const relativeY = mouseClientY - mouseDownTopOffset;
          const newMatrix = updatePanning(
            transformationMatrixAtMouseDown,
            {x: relativeX, y: relativeY},
            {x: mouseDownClientX - mouseDownLeftOffset, y: mouseDownClientY - mouseDownTopOffset},
          );
          const shouldNotPan = isPanOutsideLimits(
            newMatrix, {width: widthInCSSPixels, height: heightInCSSPixels},
            xDomainWideningFactor, yDomainWideningFactor,
          );
          if (shouldNotPan === true) {
            this._cleanUpMouseDown();
          } else {
            this.transformationMatrix = newMatrix;
            this.renderVisibleBufferWithTransformation();
            requestAnimationFrame(() => this.props.updateTransformationMatrix(newMatrix));

            this._debouncedHover();
          }
        }
      }
    }

  }, millisecondsPerFrame, {leading: true});

  private onMouseMove = ({clientX, clientY, pageX, pageY, buttons}: React.MouseEvent<any>) => {
    this.mouseClientX = clientX;
    this.mouseClientY = clientY;
    this.mousePageX = pageX;
    this.mousePageY = pageY;
    this.mouseButtons = buttons;
    if (this.isMouseDown === true) {
      this._batchPan();
    } else {
      this._throttledHover();
      this._debouncedHover();
    }
  }

  private _cleanUpMouseDown = () => {
    this.isMouseDown = false;
    this.mouseDownPageX = undefined;
    this.mouseDownPageY = undefined;
    this.mouseDownStartTime = undefined;
    this.mouseDownClientX = undefined;
    this.mouseDownClientY = undefined;
    this.transformationMatrixAtMouseDown = undefined;
    this.mouseDownTopOffset = undefined;
    this.mouseDownLeftOffset = undefined;
  }

  private onMouseDown = ({clientX, clientY, pageX, pageY, timeStamp}: React.MouseEvent<any>) => {
    const {canvas} = this;
    if (canvas !== null) {
      const {transformationMatrix} = this;
      const {top, left} = canvas.getBoundingClientRect();
      this.isMouseDown = true;
      this.mouseDownPageX = pageX;
      this.mouseDownPageY = pageY;
      this.mouseDownClientX = clientX;
      this.mouseDownClientY = clientY;
      this.mouseDownStartTime = timeStamp;
      this.transformationMatrixAtMouseDown = transformationMatrix;
      this.mouseDownTopOffset = top;
      this.mouseDownLeftOffset = left;
    }
  }

  private onMouseUp = ({pageX, pageY, timeStamp}: React.MouseEvent<any>) => {
    const {
      mouseDownPageX, mouseDownPageY, mouseDownStartTime, doubleClickDetectionTimer,
    } = this;
    if (mouseDownPageX !== undefined && mouseDownPageY !== undefined &&
        mouseDownStartTime !== undefined) {

      const retainedMouseClientX = this.mouseClientX;
      const retainedMouseClientY = this.mouseClientY;
      if (isWithinToleranceOf(pageX, mouseDownPageX, mouseDragPixelsThreshold) &&
          isWithinToleranceOf(pageY, mouseDownPageY, mouseDragPixelsThreshold) &&
          timeStamp - mouseDownStartTime < mouseClickMaxDuration) {

        if (doubleClickDetectionTimer === undefined) {
          this.doubleClickDetectionTimer = setTimeout(() => {
            // If the timer has run out and yet there's no second click, the original click
            // must have been just a single click:
            this.doubleClickDetectionTimer = undefined;
            this.onClick(retainedMouseClientX, retainedMouseClientY);
          }, mouseClickMaxDuration);
        } else {
          // If a second click occurs while the timer for the first click is still running,
          // this must be a double click:
          clearTimeout(doubleClickDetectionTimer);
          this.doubleClickDetectionTimer = undefined;
          this.onDoubleClick(retainedMouseClientX, retainedMouseClientY);
        }
      } else {
        this._cleanUpMouseDown();
      }
    }
    this._cleanUpMouseDown();
  }

  private zoomStartTopOffset: number | undefined;
  private zoomStartLeftOffset: number | undefined;
  private _batchZooms = throttle(() => {
    const {
      zoomStartTopOffset, zoomStartLeftOffset, widthInCSSPixels, heightInCSSPixels,
      deltaAccumulator, transformationMatrix, mouseClientX, mouseClientY,
    } = this;
    if (zoomStartLeftOffset !== undefined && zoomStartTopOffset !== undefined &&
        widthInCSSPixels !== undefined && heightInCSSPixels !== undefined &&
        mouseClientX !== undefined && mouseClientY !== undefined) {

      const delta = deltaAccumulator;
      this.deltaAccumulator = 0;
      const relativeX = mouseClientX - zoomStartLeftOffset;
      const relativeY = mouseClientY - zoomStartTopOffset;
      const zoomFactor = mouseDeltaToZoomFactorScale(delta);

      const newMatrix = zoom(transformationMatrix, relativeX, relativeY, zoomFactor);
      const newScaleFactor = getScaleFactor(newMatrix);

      if (newScaleFactor <= maxZoomFactorLimit && newScaleFactor >= minZoomFactorLimit) {
        this.transformationMatrix = newMatrix;
        this.renderVisibleBufferWithTransformation();
        requestAnimationFrame(() => this.props.updateTransformationMatrix(newMatrix));
        this._debouncedHover();
      }

    }
  }, millisecondsPerFrame, {leading: true});

  private deltaAccumulator: number = 0;
  private onWheel = (event: React.WheelEvent<any>) => {
    const {canvas} = this;
    if (canvas !== null) {
      const {top, left} = canvas.getBoundingClientRect();
      this.zoomStartLeftOffset = left;
      this.zoomStartTopOffset = top;

      event.preventDefault();
      event.stopPropagation();
      const {deltaY} = event;

      this.deltaAccumulator += deltaY;
      this._batchZooms();
    }
  }

  private performHitTest(mouseClientX: number | undefined, mouseClientY: number | undefined): number | undefined {
    const {
      status, canvas, widthInCSSPixels, heightInCSSPixels,
      transformationMatrix,
    } = this;
    if (canvas !== null &&
        mouseClientX !== undefined && mouseClientY !== undefined &&
        widthInCSSPixels !== undefined && heightInCSSPixels !== undefined) {
      if (status.status === AnimationStatus.FinishedCompletely ||
          status.status === AnimationStatus.InProgress) {

        const {xIntervalTree, yIntervalTree} = status;

        const {top, left} = canvas.getBoundingClientRect();
        const relativeX = mouseClientX - left;
        const relativeY = mouseClientY - top;
        return searchForHits(
          xIntervalTree, yIntervalTree, widthInCSSPixels, heightInCSSPixels, relativeX, relativeY, transformationMatrix,
        );
      } else {
        return undefined;
      }
    } else {
      return undefined;
    }
  }

  private onDoubleClick(mouseClientX: number | undefined, mouseClientY: number | undefined) {
    const result = this.performHitTest(mouseClientX, mouseClientY);
    if (result === undefined) {
      this.props.onNodeDoubleClick();
    }
  }

  private onClick(mouseClientX: number | undefined, mouseClientY: number | undefined) {
    const result = this.performHitTest(mouseClientX, mouseClientY);
    if (result !== undefined) {
      this.props.onNodeClick(result);
    }
  }

  private isZoomTweenInProgress: boolean = false;

  private _animateTransformationMatrixTransition(finalMatrix: ITransformationMatrix) {
    const {
      transformationMatrix: initialMatrix, status,
      isZoomTweenInProgress,
    } = this;

    if (isZoomTweenInProgress === false) {
      if (status.status === AnimationStatus.FinishedCompletely) {
        const newScaleFactor = getScaleFactor(finalMatrix);
        if (newScaleFactor <= maxZoomFactorLimit && newScaleFactor >= minZoomFactorLimit) {
          this.isZoomTweenInProgress = true;

          const parsedInitialMatrix = transformationMatrixJS.Matrix.from(
            initialMatrix.a, initialMatrix.b, initialMatrix.c, initialMatrix.d, initialMatrix.e, initialMatrix.f,
          );
          const parsedFinalMatrix = transformationMatrixJS.Matrix.from(
            finalMatrix.a, finalMatrix.b, finalMatrix.c, finalMatrix.d, finalMatrix.e, finalMatrix.f,
          );
          const zoomTweenTarget = {zoomTweenProgress: 0};

          const onUpdate = () => {
            const {a, b, c, d, e, f} = parsedInitialMatrix.interpolateAnim(
              parsedFinalMatrix, zoomTweenTarget.zoomTweenProgress,
            );
            this.transformationMatrix = {a, b, c, d, e, f};
            this.renderVisibleBufferWithTransformation();
          };

          const onComplete = () => {
            this.props.updateTransformationMatrix(this.transformationMatrix);
            this.isZoomTweenInProgress = false;
          };

          TweenLite.to(zoomTweenTarget, durationInSeconds, {
            zoomTweenProgress: 1,
            onUpdate,
            onComplete,
          });
        }
      }
    }
  }

  private zoomInByButton = () => {
    const {
      transformationMatrix: initialMatrix,
      widthInCSSPixels, heightInCSSPixels,
    } = this;
    if (widthInCSSPixels !== undefined && heightInCSSPixels !== undefined) {
      const finalMatrix = zoom(
        initialMatrix, widthInCSSPixels / 2, heightInCSSPixels / 2, controlButtonZoomFactor,
      );
      this._animateTransformationMatrixTransition(finalMatrix);
    }
  }
  private zoomOutByButton = () => {
    const {
      transformationMatrix: initialMatrix,
      widthInCSSPixels, heightInCSSPixels,
    } = this;
    if (widthInCSSPixels !== undefined && heightInCSSPixels !== undefined) {
      const finalMatrix = zoom(
        initialMatrix, widthInCSSPixels / 2, heightInCSSPixels / 2, 1 / controlButtonZoomFactor,
      );
      this._animateTransformationMatrixTransition(finalMatrix);
    }
  }
  private resetTransformations = () => {
    const finalMatrix = getIdentityTransformMatrix();
    this._animateTransformationMatrixTransition(finalMatrix);
  }

  private swallowMouseEvent = (e: React.MouseEvent<any>) => e.stopPropagation();

  render() {
    const {showZoomButtons} = this.props;

    let zoomButtons: JSX.Element | null;
    if (showZoomButtons === true) {
      zoomButtons = (
        <div className={styles.container} onMouseDown={this.swallowMouseEvent} onDoubleClick={this.swallowMouseEvent}>
          <button onClick={this.zoomInByButton}>+ ZOOM</button>
          <button onClick={this.zoomOutByButton}>- ZOOM</button>
          <button onClick={this.resetTransformations}>RESET ZOOM</button>
        </div>
      );
    } else {
      zoomButtons = null;
    }

    return (
      <Root ref={this.rememberRootEl}
        onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave}
        onMouseMove={this.onMouseMove} onMouseUp={this.onMouseUp}
        onMouseDown={this.onMouseDown} onWheel={this.onWheel}>

        <Canvas ref={this.rememberCanvas}/>
        {zoomButtons}
      </Root>
    );
  }
}
